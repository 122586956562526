import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import { MarkdownContent } from '../components/MarkdownRenderer'
import { Helmet } from 'react-helmet'

const BasePage = ({ event, events }) => (
  <>
    <Helmet title={get(event, 'frontmatter.title')} />
    <div className={'container has-navbar-fixed-top m-b-xl'}>
      <div className='columns'>
        <div className='column is-four-fifths'>
          <article className='message'>
            <div className='message-header'>
              <p className='is-size-6 m-r-md'>{get(event, 'frontmatter.title')}</p>
              <p className='is-size-7 is-pulled-right'>{get(event, 'frontmatter.date')}</p>
            </div>
            {
              !event.fields.slug.includes('initial') &&
              <div className='message-body'>
                <MarkdownContent html={get(event, 'html')} />
              </div>
            }
          </article>
        </div>
        <div className='column'>
          <div className='tile is-parent p-none'>
            <div className='tile m-l-lg'>
              <aside className='menu is-child'>
                <p className='menu-label is-size-4-desktop'>
                  Alle Events
                </p>
                <ul className='menu-list'>
                  {
                    events.map(({ node }, index) => (
                      <li key={index} className='m-b-md'>
                        <Link
                          to={node.fields.slug}
                        >
                          {
                            !node.fields.slug.includes('initial') &&
                            <div className='subtitle is-size-7 m-b-sm'>{node.frontmatter.date}</div>
                          }
                          <div className='subtitle is-size-6'>{node.frontmatter.title}</div>
                        </Link>
                      </li>
                    ))
                  }
                </ul>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default ({ data: { event, events } }) => (
  <BasePage event={event} events={events.edges} />
)

export const pageQuery = graphql`
  query Event($id: String!) {
    event: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        date(formatString: "dddd, DD MMMM YYYY HH:mm", locale: "de")
      }
      fields {
        slug
      }
    }
    events: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "EventPage"} } },
      sort: {fields: [frontmatter___date], order: DESC}
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            date(formatString: "dddd, DD MMMM YYYY HH:mm", locale: "de")
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
