import React from 'react'
import styled, { css } from 'styled-components'
import keys from 'lodash/keys'

const MarkdownStyles = styled.div`
  a {
    img {
      border: 1px solid ${({ theme }) => theme.constants.colors.primary.cp20};
    }
    
    :not([href='']) {
      ${({ theme }) => keys(theme.constants.breakpoints).map(bp => css`
        @media (min-width: ${theme.constants.breakpoints[bp]}px) {
          &::after {
            content: "";
            height: ${theme.constants.spacings.md[bp]}px;
            width: ${theme.constants.spacings.md[bp]}px;
            background: url(/assets/img/link.svg) no-repeat bottom;
            background-size: contain;
            display: inline-block;
            margin-left: ${theme.constants.spacings.sm[bp]}px;
          }
        }
      `)}
    }
  }
`

export const MarkdownContent = ({ html }) => (
  <div className='content'>
    <MarkdownStyles dangerouslySetInnerHTML={{ __html: html }} />
  </div>
)
